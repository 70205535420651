<template>
  <div>
    <div class="px-4">
      <div class="mb-2">
        <div>
          <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div class="flex">
                  <a href="/kundeninfo" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="mt-2 md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Umsätze & Kundeninformationen
            </h2>
          </div>
          <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          </div>
        </div>
      </div>
      <div class="mt-4">
        <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="item in userSalesStats" :key="item.gender" class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div class="absolute rounded-md bg-indigo-500 p-3">
                <UserIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p class="ml-16 truncate text-sm font-medium text-gray-500">{{  parseFloat(item.prozent).toFixed(2) }}% sind</p>
            </dt>
            <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p class="text-2xl font-semibold text-gray-900">
                <span v-if="item.gender === 'male'">Männlich</span>
                <span v-else-if="item.gender === 'female'">Weiblich</span>
                <span v-else>Divers</span>
              </p>
              <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div class="text-sm">
                  <span class="font-medium text-indigo-600 hover:text-indigo-500">Durchschnittsalter: {{ parseInt(item.durchschnittsalter) }} Jahre</span>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-2">
            <div class="relative mt-2 rounded-md shadow-sm">
              <input v-model="filterUser" type="search" name="searchUser" id="searchUser" class="px-4 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Suche nach Kunden..." />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="relative mt-2 rounded-md shadow-sm">
              <label for="filterEvent" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Eventauswahl</label>
              <select v-model="filterEvent" id="filterEvent" class="block w-full rounded-md border-0 py-2 pl-4 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-l sm:leading-6" >
                <option value="0">
                  Alle
                </option>
                <option v-for="event in events" :value="event.id" :key="event.id">
                  {{ event.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">
                    <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                  </span>
              </div>
              <label for="filterStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Von</label>
              <input v-model="filterStart" type="datetime-local" name="filterStart" id="filterStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">
                    <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                  </span>
              </div>
              <label for="filterEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Bis</label>
              <input v-model="filterEnd" required type="datetime-local" name="filterEnd" id="filterEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div class="sm:col-span-1 pt-1">
            <button @click="download" style="float: right;" class="rounded-md bg-blue-600 px-3 py-2 text-l font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              Download
            </button>
          </div>
        </div>
      </div>
      <div class="bg-white p-4 rounded shadow mt-4" v-if="!this.config.loading">
        <div class="mb-4">
          <label class="text-base font-semibold text-gray-900">Gruppieren nach:</label>
          <fieldset>
            <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div class="flex items-center">
                <input v-model="group" value="event" id="event" name="notification-method" type="radio" :checked="group === 'event'" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                <label for="event" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Events</label>
              </div>
              <div class="flex items-center">
                <input v-model="group" value="user" id="user" name="notification-method" type="radio" :checked="group === 'user'" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                <label for="user" class="ml-3 block text-sm font-medium leading-6 text-gray-900">User</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div v-if="group === 'user'">
          <table class="w-full text-left">
            <thead class="bg-white">
            <tr>
              <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                Name
                <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Letzer Kauf</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">Umsatz</th>
              <th scope="col" class="relative py-3.5 pl-3 text-sm font-semibold text-right">
                Mehr Infos
              </th>
            </tr>
            </thead>
            <tbody>
              <sales-by-user-comp v-for="customer in salesByUser" :key="customer.userId" :customer="customer" @user-info="u => userInfoId = u" />
            </tbody>
          </table>
        </div>
        <div v-else>
          <table class="w-full text-left">
            <thead class="bg-white">
            <tr>
              <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                Event
                <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell text-right">Gesamt-Umsatz</th>
              <th scope="col" class="relative py-3.5 pl-3 text-sm font-semibold text-right">
                Mehr Infos
              </th>
            </tr>
            </thead>
            <tbody>
              <sales-by-event-comp v-for="event in salesByEvent" :key="event.eventId" :event="event" @user-info="u => userInfoId = u" />
            </tbody>
          </table>
        </div>
      </div>
      <loader v-else />
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import salesByUserComp from "@/components/sales/salesByUser";
import salesByEventComp from "@/components/sales/salesByEvent";
import {aSyncData, stringToDate} from "@/modules/main";
import Loader from "@/components/loader/ticketLoader";
import { MagnifyingGlassIcon, ClockIcon,UserIcon  } from '@heroicons/vue/24/outline'

export default {
  name: "customerInfoView",
  title: "Kundeninformation",
  components: {
    Loader,
    salesByUserComp,
    salesByEventComp,
    MagnifyingGlassIcon,
    ClockIcon,
    UserIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      sales: [],
      filterEvent: 0,
      filterUser: null,
      filterStart: '2020-01-01 00:00:00',
      filterEnd: '2030-01-01 00:00:00',
      group: 'user',
      userInfoId: 0,
      userSalesStats: []
    }
  },
  computed: {
    salesByUser(){
      let user = {}
      this.salesFiltered.forEach(s => {
        if(!(s.userId in user)) user[s.userId] = {userId: s.userId, firstname: s.firstname, lastname: s.lastname, email: s.email, events: {}}
        if(!(s.eventId in user[s.userId].events)) user[s.userId].events[s.eventId] = {eventId: s.eventId, eventStart: s.eventStart, eventTitle: s.eventTitle, sales: []}
        user[s.userId].events[s.eventId].sales.push(s)
        if(!('lastSale' in user[s.userId].events[s.eventId]) || user[s.userId].events[s.eventId].lastSale < stringToDate(s.date)) user[s.userId].events[s.eventId].lastSale = stringToDate(s.date)
      })
      console.log(user)
      return user
    },
    salesByEvent(){
      let user = {}
      this.salesFiltered.forEach(c => {
        if(!(c.eventId in user)) user[c.eventId] = {eventId: c.eventId, eventStart: c.eventStart, eventTitle: c.eventTitle, customer: {}}
        if(!(c.userId in user[c.eventId].customer)) user[c.eventId].customer[c.userId] = {userId: c.userId, firstname: c.firstname, lastname: c.lastname, email: c.email, sales: []}
        user[c.eventId].customer[c.userId].sales.push(c)
        if(!('lastSale' in user[c.eventId].customer[c.userId]) || user[c.eventId].customer[c.userId].lastSale < stringToDate(c.date)) user[c.eventId].customer[c.userId].lastSale = stringToDate(c.date)
      })
      return user
    },
    events(){
      let events = []
      this.sales.forEach(s => {
        if(!events.some(e => e.id === s.eventId)) events.push({id: s.eventId, title: s.eventTitle, start: s.eventStart})
      })
      return events
    },
    salesFiltered(){
      let user =  this.sales.filter(s => {
        const start = stringToDate(s.eventStart)
        return start >= new Date(this.filterStart) && start <= new Date(this.filterEnd)
      })

      if(this.filterUser){
        user = user.filter(s => {
          return s.firstname.includes(this.filterUser) || s.lastname.includes(this.filterUser) || s.email.includes(this.filterUser)
        })
      }

      if(this.filterEvent){
        user = user.filter(s => {
          return s.eventId === this.filterEvent
        })
      }

      return user
    },
  },
  mounted() {
    this.getSales()
    this.getUserSalesStats()
  },
  methods: {
    getSales(){
      const _this = this
      console.log(this.config.organizerId)
      aSyncData('sales/getSales', {
        organizerId: this.config.organizerId,
        start: this.filterStart,
        end: this.filterEnd
      })
          .then(r => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.sales = r.data
              _this.config.loading = false
            }
          })
    },
    getUserSalesStats(){
      const _this = this
      console.log(this.config.organizerId)
      aSyncData('sales/getUserSalesStats', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.userSalesStats = r.data
              _this.config.loading = false
            }
          })
    },
    download(){
      let csv = ''

      for(let row = 0; row < this.salesFiltered.length; row++){
        let keysAmount = Object.keys(this.salesFiltered[row]).length
        let keysCounter = 0

        // If this is the first row, generate the headings
        if(row === 0){

          // Loop each property of the object
          for(let key in this.salesFiltered[row]){
            // This is to not add a comma at the last cell
            // The '\r\n' adds a new line
            csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
            keysCounter++
          }
        }

        for(let key in this.salesFiltered[row]){
          csv += this.salesFiltered[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }

        keysCounter = 0
      }

      let link = document.createElement("a")
      link.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodeURI(csv));
      link.setAttribute("download", "sales.csv");

      link.click();
    },
  }
}
</script>

<style>

</style>